.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.ms-Check-checkHost {
  height: 100%;
}

.ms-List-cell:hover, .ms-List-cell:focus, .ms-List-cell:focus-within {
  & .user-action {
    visibility: visible;
  }
}

.user-action.active {
  visibility: visible;
}

.ms-Nav-groupContent {
  margin-bottom: 1.25rem;
}

.ms-Dialog-main {
  min-height: 165px;
  min-width: 350px;
  max-width: 500px;
}

.ms-Dropdown-callout {
  min-width: fit-content !important;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  padding: 16px;
  
  .ms-Spinner-circle {
    width: 64px;
    height: 64px;
    border-width: 5px;
  }

  .ms-Spinner-label {
    font-size: 24px;
    font-weight: 300;
  }

  &--small {
    padding: 16px;

    .ms-Spinner-circle {
      width: 32px;
      height: 32px;
      border-width: 3px;
    }
  
    .ms-Spinner-label {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.Toastify {
  position: relative;
  z-index: 10000000;
}

// .ms-Callout-main {
//   max-height: 40vh !important;
// }