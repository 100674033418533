.autocomplete {
  position: relative;
  $icon: & + "__icon";

  &__code {
    margin-left: 1ch;
  }

  &__label {
    display: block;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
      -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: #323130;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;

    &--required {
      color: rgb(164, 38, 44);
    }
  }

  &__input-container {
    box-shadow: none;
    margin: 0px;
    box-sizing: border-box;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
      -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    position: relative;
    outline: 0px;
    user-select: none;
    padding: 1px 29px 1px 9px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(96, 94, 92);
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 31px;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &--error {
      border-color: rgb(164, 38, 44);
    }

    &--disabled {
      border-color: rgb(243, 242, 241);
      background-color: rgb(243, 242, 241);
      color: rgb(161, 159, 157);

      & :not(input) {
        pointer-events: none;
      }

      & input { 
        color: rgb(161, 159, 157);
      }
    }

    &:focus-within {
      border-width: 2px;
      padding: 0 28px 0 8px;

      #{$icon} {
        line-height: 28px;
        right: 8px;
      
        &--small {
          height: 20px;
          line-height: 22px;
        }
      }
    }
  }

  &__input {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
      -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border-radius: 0px;
    border: none;
    background: none transparent;
    color: rgb(50, 49, 48);
    width: 100%;
    min-width: 0px;
    text-overflow: ellipsis;
    outline: 0px;
  }

  &__input-helper {
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px;
    padding-top: 5px;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 9px;
    height: 29px;
    line-height: 30px;
    cursor: pointer;

    &--small {
      height: 22px;
      line-height: 24px;
    }

    & i {
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-weight: normal;
      speak: none;
      font-family: FabricMDL2Icons;
      color: rgb(96, 94, 92);
      font-size: 12px;
      pointer-events: none;
    }
  }

  &__checkbox {
    padding-right: 4px;
  }

  &__listbox {
    // width: 100%;
    // width: 400px;
    margin: 0;
    margin-top: 4px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 3.2px 7.2px 0px,
      rgba(0, 0, 0, 0.13) 0px 0.6px 1.8px 0px;
    border-radius: 2px;
    padding: 0;
    z-index: 1;
    // position: absolute;
    position: relative;
    list-style: none;
    background-color: white;
    overflow: auto;
    max-height: 40vh;
    height: fit-content;
    font-size: 14px;
    font-weight: 400;
    z-index: 100000000000;

    & li {
      outline: transparent;
      position: relative;
      font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
        -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      font-weight: 400;
      box-sizing: border-box;
      border: 1px solid transparent;
      display: flex;
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      border-radius: 0px;
      color: rgb(50, 49, 48);
      background-color: transparent;
      align-items: center;
      width: 100%;
      min-height: 36px;
      line-height: 20px;
      overflow-wrap: break-word;
      user-select: none;
      padding: 8px;

      &.divider {
        border-top: 1px solid rgb(237, 235, 233);
        height: 0;
        padding: 0;
        min-height: unset;
      }

      &.header {
        position: sticky;
        top: 0;
        z-index: 1;
        color: #f5970f;
        font-weight: 600;
        background-color: white;
      }

      &[aria-selected="true"] {
        background-color: rgb(237, 235, 233);
      }

      &[data-focus="true"] {
        background-color: rgb(243, 242, 241);
        cursor: pointer;
      }

      &:active {
        background-color: rgb(243, 242, 241);
      }
    }
  }
}
