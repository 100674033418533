.gdpr-content {
    margin: 60px 100px;
    font: 13pt sans-serif;
}

.gdpr-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}

.gdpr-icon {
    padding-right: 10px;
}

.gdpr-link {
    margin: 10px;
}

@-webkit-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-moz-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@-o-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

.show-box {
    -webkit-animation: SHOW-BOX 1.5s ease;
    -moz-animation: SHOW-BOX 1.5s ease;
    -o-animation: SHOW-BOX 1.5s ease;
    animation: SHOW-BOX 1.5s ease;
}