.aside {
  &__top {
    padding: 0.5rem 0.525rem;
    display: flex;
    border-bottom: 1px solid #EDEBE9;

    .globalButton {
      font-size: 1.0625rem;
      height: 1.0625rem;
      width: 1.0625rem;
      color: #F5970F;
    }
  }

  button {
    background-color: transparent;
    border: none;
    display: inline-flex;
    padding: 0.4375rem;
    align-items: center;
    border-radius: 0.125rem;
    
    &:hover {
      background-color: #E1DFDD; 
      cursor: pointer;
    }
  }

  & .moreButton {
    font-size: 1.125rem;
    height: 1.125rem;
    width: 1.125rem;
    color: black;
  }

  & .edit-button {
    padding: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;

    &:hover, &:focus {
      background-color: transparent;

      & .edit-button__icon {
        color: #F5970F;
      }
    }

    &__icon {
      font-size: 1rem;
      height: 1rem;
      width: 1rem;
      color: #605E5C;
    }
  }

  & .restaurant-list {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.875rem 1rem;

      h2 {
        margin: 0;
        color: #323130;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }

    &__edit {
      display: flex;
      align-items: center;

      &-container {
        display: grid;
        grid-template-columns: 1fr 2.65rem;
        width: 100%;
        margin: 0;
      }
    }

    &__link {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.25rem;
      color: rgb(50, 49, 48);
      text-align: left;

      &:hover, &:focus { 
        color: rgb(50, 49, 48);
      }
    }

    &__collapse {
      a {
        padding-right: 0;
      }

      & > div > button {
        padding: 0 0.375rem 0 1.75rem;
        margin: 0;
        color: #323130;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
  }

  & .users-list, & .settings-list, & .logs-list {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.625rem 1rem;

      h2 {
        margin: 0;
        color: #323130;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
      }
    }
  }

  & .ms-Button-label {
    margin: 0;
  }

  & .ms-Nav-link {
    padding-right: 1.125rem;
  }

  & .ms-Nav-chevronButton:after {
    display: none;
  }
  .restaurant-list__link--closed {
    color: red;

    &:hover {
      color: red;
    }
  }

  .status-dropdown {
    width: 45%;
  }
}