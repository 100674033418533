.main {
  display: grid;
  grid-template-columns: 18.25rem calc(100vw - 18.25rem);
  align-items: center;
  height: calc(100vh - 3rem);

  &__section {
    height: 100%;
  }

  &__content {
    padding: 2rem;
    height: calc(100vh - 6rem);
    position: relative;
  }

  &__aside {
    background-color: #F3F2F1;
    height: 100%;
    overflow-y: auto;

    .is-selected a {
      background-color: #E1DFDD;
      // border: none;
    }

    .ms-Nav-compositeLink:hover a {
      background-color: #E8E6E3;
    }

    .ms-Nav-compositeLink {
      a, div {
        height: 2.25rem;
        line-height: 2.25rem;
  
        span {
          height: 2.125rem;
          line-height: 2.125rem;
        }
      }
      
      button {
        height: 2.125rem;
        padding: 0;
        justify-content: center;

        .ms-Nav-chevron {
          position: relative;
          left: 0;
          height: 2.125rem;
          line-height: 2.125rem;
        }
      }
    }
  }
}