.header {
  background: #F5970F;
  padding: 0.5rem 0.625rem 0.5rem 1rem;
  display: grid;
  // grid-template-columns: 17.25rem minmax(1rem, 1fr) 2.375rem;
  grid-template-columns: 17.25rem minmax(1rem, 1fr) auto;
  align-items: center;

  &__logo {
    display: flex;
    align-items: center;

    a {
      color: white;
      font-size: 1rem;
      line-height: 1.375rem;
      margin-left: 1.5625rem;
      font-weight: 500;
      text-decoration: none;
    }
  }

  &__user {
    width: 1.75rem;
    height: 1.75rem;
    position: relative;
    background-color: pink;
    border-radius: 1.75rem;
    margin-left: auto;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: '';
      position: absolute;
      border: 0.125rem solid white;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 0.75rem;
      bottom: -0.0625rem;
      right: -0.0625rem;
      background-color: #B70000;
    }

    &.active:after {
      background-color: #6BB700;
    }

    &-container {
      display: flex;
      align-items: center;

      span {
        margin-right: 0.5rem;
        color: white;
      }
    }
  }

  &__search-box {
    border: none;
    background-color: rgba(255,255,255,0.8);
    color: #004578;

    input {
      color: #004578;

      &::placeholder {
        color: #004578;
        opacity: 1;
      }
      
      &:-ms-input-placeholder {
        color: #004578;
      }
      
      &::-ms-input-placeholder {
        color: #004578;
      }
    }
  }

  & .waffle {
    font-size: 1rem;
    height: 1rem;
    width: 1rem;
    color: white;
  }

  .lang-wrapper {
    border: none;
    background: transparent;
    color: white;
    margin-right: 15px;
    outline: none;

    option {
      background-color: #fff;
      color: #000;
    }
  }
}
