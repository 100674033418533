.action {
  padding: 0.4375rem 1.5rem;
  border-bottom: 1px solid #EDEBE9;
  display: flex;
  min-height: 3rem;

  &__button {
    display: flex;
    margin-right: 1rem;

    button {
      background: transparent;
      border: none;
      display: inline-flex;
      height: 2.0625rem;
      padding: 0.5rem 0.5rem;
      align-items: center;

      &:hover {
        cursor: pointer;
        color: #F5970F;
      }

      span {
        padding: 0 0.625rem;
      }
    }

    & .addButton {
      font-size: 1.0625rem;
      height: 1.0625rem;
      width: 1.0625rem;
      color: #F5970F;
    }
  } 
}