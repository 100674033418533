ul.reports-list {
  list-style-type: none;
  margin: 20px;
  padding: 0;
}

li.report-item {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.report-icon {
  padding-right: 10px;
}

.report-item > a {
  font-size: 12pt;
}