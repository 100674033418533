.logs {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__heading {
    flex: 0 1 auto;
    font-size: 1.25rem;
    font-weight: 500;
    color: #323130;
    text-align: left;
    margin: 0;
    height: 2rem;

    &::first-letter {
      text-transform: capitalize;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button {
      margin-left: 1rem;
    }
  }

  &__list {
    position: relative;
    flex: 1 1 auto;
  
    &-cell {
      &--text-transform {
        text-transform: capitalize;
      }
    }
  }

  &__buttons {
    margin-top: 12px;
    text-align: center;
  }
}