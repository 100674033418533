.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    z-index: 1001;
    max-width: 50%;
  }

  .buttons-field {
    display: flex;
    gap: 20px;
  }

  .cancel-button {
    background-color: red;
  }
  
  .cancel-button:hover {
    background-color: darkred;
  }