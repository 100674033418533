.file-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}

.file-actions .download-button {
    background-color: #F5970F;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 45px;
}

.file-actions .download-button:hover {
    background-color: #F5970F;
    opacity: .7;
}

.file-actions .upload-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.file-actions .upload-button:hover {
    background-color: #1d862b;
    margin-right: 25px;
}

.file-actions .fileUpload {
    margin-top: 15px;
    display: flex;
    align-items: center;
}

.file-actions .fileUpload span {
    font-weight: bold;
}

.file-actions .fileUpload .uploadContainer {
    margin-left: 15px;
}
