.policies__container {
  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 600px;

    &:hover {
      cursor: pointer;
      background-color: #F3F2F1;
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
  }

  i {
    margin-right: 8px;
  }

  .IconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    background-color: #f0f0f0;
    border-radius: 50%;
  }
}

