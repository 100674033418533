.dictionary-list {
  --columns: 2;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(var(--columns), auto);
  grid-template-rows: repeat(auto-fill, 32px);
  gap: 0 16px;
  align-items: center;
  margin-top: 16px;

  &__item {
    &--bold {
      font-weight: 500;
    }

    &--capitalize {
      text-transform: capitalize;
    }

    &--center {
      text-align: center;
    }
  }
}