.users {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__heading {
    flex: 0 1 auto;
    font-size: 1.25rem;
    font-weight: 500;
    color: #323130;
    text-align: left;
    margin: 0.875rem 0 0.375rem;
    text-transform: capitalize;
  }

  &__filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(11.25rem, 1fr));
    grid-gap: 1rem;
  }

  &__list {
    position: relative;
    flex: 1 1 auto;
  }

  & .list { 
    &__cell {
      font-size: 0.75rem;
      color: #605E5C;
      line-height: 1rem;
      display: flex;
      align-items: center;
      height: 100%;
  
      b {
        color: #323130;
        margin-right: 1ch;
      }
    }
  
    &__action {
      visibility: hidden;
  
      button {
        background-color: transparent;
        border: none;
        display: inline-flex;
        padding: 0.4375rem;
        align-items: center;
        border-radius: 0.125rem;
        
        &:hover {
          background-color: #E1DFDD; 
          cursor: pointer;
        }
      }
  
      & .moreButton {
        font-size: 1.125rem;
        height: 1.125rem;
        width: 1.125rem;
        color: black;
      }
    }
  
    &__username {
      display: grid;
      grid-template-columns: auto 2rem;
      grid-gap: 0.5rem;
      align-items: center;
  
      & .content {
        display: flex;
        flex-direction: column;
  
        &__mail {
          font-size: 0.75rem;
          color: #605E5C;
          line-height: 1rem;  
        }
  
        &__content {
          font-size: 0.875rem;
          color: #323130;
          line-height: 1.25rem;        
        }
      }
  
      & .avatar {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        background-color: pink;
        border-radius: 1.5rem;
        margin-left: auto;
    
        &:after {
          content: '';
          position: absolute;
          border: 0.125rem solid white;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 0.75rem;
          bottom: -0.0625rem;
          right: -0.0625rem;
          background-color: #B70000;
        }
    
        &.active:after {
          background-color: #6BB700;
        }
      }
    }
  }

  .ms-TextField-fieldGroup {
    height: 24px !important;
  }

  .ms-TextField-field {
    font-size: 13px !important;
    line-height: 20px !important;
  }

  label {
    font-size: 13px !important;
  }

  .ms-Dropdown-caretDownWrapper {
    height: 22px !important;
    line-height: 22px !important;
  }

  .ms-Dropdown {
    height: 24px !important;
    font-size: 13px !important;
    line-height: 21px !important;
  }
}

.user-action {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 6px;
    margin-bottom: 12px;
  }
}