.form {

  .separator {
    padding: 0.6875rem 0;
  
    &::before {
      background: #D8D8D8;
    }
  }

  &__column {
    display: grid;
    grid-gap: 0.5rem;
  }

  &__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.3125rem;
    grid-row-gap: 0.5rem;
    // margin: 1rem 0;
  }

  &__field--full-width {
    grid-column: 1 / -1;
  }
  
  .ms-Label {
    color: rgb(50, 49, 48);
  }
}